import ForgotSalespersonId from "@components/Dealer/ForgotSalespersonId"
import { Layout } from "@components/Layout"
import { SEO } from "@components/SEO"
import { graphql, StaticQuery } from "gatsby"
import React from "react"

const ForgotSalespersonIdPage = () => {
    return (
        <StaticQuery
            query={graphql`
                {
                site {
                    siteMetadata {
                    apiUrl
                    }
                }
                }
            `}
            render={
                data => {
                    const apiUrl = data.site.siteMetadata.apiUrl
                    return (
                        <Layout version="dealer">
                            <SEO title="Forgot Salesperson Id" />
                            <ForgotSalespersonId apiUrl={apiUrl} />
                        </Layout>
                    )
                } }
        />

    )
}
export default ForgotSalespersonIdPage