import { AuthPageWrapper, ForgotPasswordWrapper } from "@auth/index.styles"
import { KpButton } from "@elements/index"
import { AuthFormLabel, AuthTitle } from "@styles/Global.forms"
import { Paragraph2 } from "@styles/Global.styles"
import theme from "@styles/theme"
import { ButtonTypes } from "@utils/Constant"
import ErrorMessage from "../../auth/ErrorMessage"
import React from "react"
import { Form, Input } from "semantic-ui-react"
import { DealerSignInLink } from "./OwnerDetailsForm.style"
import { Link, navigate } from "gatsby"
import { validateDealerEmail } from "@auth/Validator"
import { DealerService } from "@services/DealerService"

const ForgotSalespersonId = (props: { apiUrl: string }) => {
    const [email, setEmail] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState("")

    const handleForgotSalesperonId = (e: any) => {
        e.preventDefault()

        if (validateDealerEmail(email, setError)) {
            setLoading(true)
            DealerService.sendSalespersonId(props.apiUrl, email.trim())
                .then(response => {
                    if (response.Success && response.Status == 200) {
                        setError("")
                        setLoading(false)
                        navigate("/dealer-login")
                    } else {
                        setError(response.Message)
                        setLoading(false)
                    }
                })
                .catch(exception => {
                    setError(exception.message)
                    setLoading(false)
                })
        }
    }

    const updateEmail = (email: string) => {
        setError("");
        setEmail(email);
    }

    return (
        <AuthPageWrapper>
            <ForgotPasswordWrapper>
                <AuthTitle>Forgot Salesperson Id?</AuthTitle>
                <Paragraph2 color={theme.brand.colors.darkGrey}>
                    Enter your Email below and we will send Salesperson Id to your Email
                </Paragraph2>
                <Form onSubmit={handleForgotSalesperonId}>
                    <Form.Field>
                        <AuthFormLabel>EMAIL</AuthFormLabel>
                        <Input
                            type="email"
                            name="email"
                            value={email}
                            id="email"
                            placeholder="yourname@email.com"
                            onChange={e => updateEmail(e.target.value)} />
                    </Form.Field>
                    <Form.Field
                        style={{ textAlign: "center", padding: "10px 0 10px 0" }}
                    >
                        <KpButton
                            id="signin-submit"
                            color={theme.brand.colors.green}
                            fullWidth="mobile tablet computer"
                            buttonType={ButtonTypes.Primary}
                            loading={loading}
                            type="submit"
                        >
                            {loading ? "Loading..." : "Send Salesperson Id"}
                        </KpButton>
                    </Form.Field>
                    {error && <ErrorMessage errorMessage={error} />}
                </Form>
                <DealerSignInLink>
                    <Link to="/dealer-login">Back To Dealer Login</Link>
                </DealerSignInLink>
            </ForgotPasswordWrapper>
        </AuthPageWrapper>
    )
}
export default ForgotSalespersonId